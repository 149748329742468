import React, { useState } from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css";
import mockup1 from "../../assets/mockup1.png";
import mockup2 from "../../assets/mockup2.png";
import mockup3 from "../../assets/mockup3.png";
import design4 from "../../assets/d11.png";
import design2 from "../../assets/d2-min.png";
import design3 from "../../assets/d8-min.png";
import design1 from "../../assets/d3-min.png";
import design5 from "../../assets/d14.png";

// Mockup data
const mockups = [
  {
    src: mockup1,
    title: "Advertisement",
    description:
      "This mockup includes two poster designs. The first poster advertises a delicious food product with vibrant colors and appetizing imagery, ideal for a restaurant or food brand. The second poster promotes a travel service, highlighting exotic destinations with a clean and engaging layout to attract potential travelers.",
  },
  {
    src: mockup2,
    title: "Environment",
    description:
      "A captivating poster design showcasing the natural beauty of Bali. The layout highlights stunning landscapes, serene beaches, and vibrant cultural elements, using a harmonious color palette to evoke tranquility and allure. Perfect for promoting tourism or nature-inspired events.",
  },
  {
    src: mockup3,
    title: "Application",
    description:
      "This mockup showcases a poster for an application service. The design features sleek graphics, a minimalist color scheme, and user-friendly illustrations to highlight the app's functionality and benefits, appealing to tech-savvy audiences.",
  },
];

const moreDesigns = [design1, design2, design3, design4, design5];
export function MockupGallery() {
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);

  const handleImageClick = (src: string) => {
    setFullscreenImage(src);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <Container>

       <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <hr />
        <br />
        <h2 style={{textAlign:"center"}}>Design Art</h2>
      </ScrollAnimation>
      {mockups.map((mockup, index) => {
        const reverse = index % 2 !== 0; // Alternate left-right
        return (
          <Section key={index} reverse={reverse}>
            <ScrollAnimation
              animateIn={reverse ? "fadeInRight" : "fadeInLeft"}
              duration={1}
            >
              <ImageWrapper>
                <img
                  src={mockup.src}
                  alt={mockup.title}
                  onClick={() => handleImageClick(mockup.src)}
                />
                <Overlay className="overlay">{mockup.title}</Overlay>
              </ImageWrapper>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn={reverse ? "fadeInLeft" : "fadeInRight"}
              duration={1}
            >
              <DescriptionWrapper reverse={reverse}>
                <Title>{mockup.title}</Title>
                <Text>{mockup.description}</Text>
              </DescriptionWrapper>
            </ScrollAnimation>
          </Section>
        );
      })}

      {fullscreenImage && (
        <FullscreenOverlay onClick={closeFullscreen}>
          <FullscreenImage src={fullscreenImage} alt="Fullscreen mockup" />
        </FullscreenOverlay>
      )}

      <MoreDesignsSection>
        <MoreDesignsTitle>More</MoreDesignsTitle>
        <MoreDesignsGrid>
          <LargeImageWrapper>
            <img
              src={moreDesigns[0]}
              alt="Large design"
              onClick={() => handleImageClick(moreDesigns[0])} // Klik gambar besar untuk fullscreen
            />
          </LargeImageWrapper>
          <SmallImagesWrapper>
            {moreDesigns.slice(1, 5).map((design, index) => (
              <SmallImageWrapper key={index}>
                <img
                  src={design}
                  alt={`Design ${index + 2}`}
                  onClick={() => handleImageClick(design)} // Klik gambar kecil untuk fullscreen
                />
              </SmallImageWrapper>
            ))}
          </SmallImagesWrapper>
        </MoreDesignsGrid>
      </MoreDesignsSection>
      <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <br /><br /><hr />
        </ScrollAnimation>
    </Container>
  );
}


/* Styled Components */
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 90px;
`;

const Section = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  margin: 40px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ImageWrapper = styled.div`
  flex: 1 1 50%; /* Half of the container */
  text-align: center;
  position: relative; /* Enables overlay positioning */
  overflow: hidden; /* Prevents the overlay from going out of bounds */

  img {
    width: 100%;
    max-width: 500px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  }

  &:hover .overlay {
    transform: translateY(0); /* Move overlay up on hover */
    opacity: 1; /* Make overlay visible */
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0; /* Start at the bottom */
  left: 0;
  right: 0;
  height: 50%; /* Covers half the image */
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  color: #fff; /* White text */
  display: flex;
  justify-content: center; 
  align-items: center;
  opacity: 0; /* Hidden by default */
  transform: translateY(100%); /* Start below the image */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth animation */
  border-radius: 0 0 8px 8px; /* Matches the image's border-radius */
  font-size: 16px; /* Adjusted font size */
  font-weight: 500; /* Lighter font weight */
`;


const DescriptionWrapper = styled.div<{ reverse: boolean }>`
  flex: 1 1 50%; /* Half the container */
  max-width: 600px; /* Max width for description */
  padding: ${({ reverse }) => (reverse ? "0 40px 0 0" : "0 0 0 40px")};
  text-align: ${({ reverse }) => (reverse ? "right" : "left")};

  @media (max-width: 768px) {
    flex: 1 1 100%; /* Full width on small screens */
    padding: 20px 0;
    text-align: justify;
    font-size: 1.5rem;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #777;
`;

const FullscreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
`;

const FullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
`;

const MoreDesignsSection = styled.div`
  margin-top: 60px;
  text-align: center;
`;

const MoreDesignsTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const MoreDesignsGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr; /* Gambar besar (kiri) mengambil 2 kolom, gambar kecil (kanan) 1 kolom */
gap: 6px; /* Mengurangi jarak antar gambar */
align-items: center;

@media (max-width: 768px) {
  grid-template-columns: 1fr; /* Semua gambar menjadi satu kolom di layar kecil */
}
`;

const LargeImageWrapper = styled.div`
grid-column: 1 / 2; /* Gambar besar di kolom pertama */
width: 100%;
height: auto;
border-radius: 8px;
overflow: hidden;

img {
  width: 99.3%; /* Gambar besar mengisi kolom sepenuhnya */
  height: auto;
  object-fit: cover;
}
`;

const SmallImagesWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr; /* Grid 2x2 untuk gambar kecil */
gap: 5px; /* Mengurangi jarak antar gambar kecil */
`;

const SmallImageWrapper = styled.div`
width: 100%;
padding-top: 100%; /* Membuat persegi */
position: relative;
border-radius: 8px;
overflow: hidden;

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%; /* Mengisi seluruh ruang gambar kecil */
  height: 99%; /* Mengisi seluruh ruang gambar kecil */
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}
`;
