import { Container } from './styles';
import reactIcon from '../../assets/react-icon.svg';
import linkedin from '../../assets/linkedin.svg';
import githubIcon from '../../assets/github.svg';
import whatsapp from '../../assets/whatsapp.svg';
import telegram from '../../assets/telegram.svg';
import instagramIcon from '../../assets/instagram.svg';
import s from '../../assets/s.gif';
import eye from '../../assets/eye.gif';

export function Footer() {
  // Set visitor count to a static value for now
  const visitorCount = 79; // You can change this to whatever value you want

  return (
    <Container className="footer">
      <a  href="https://github.com/septianhdyt" className="logo" style={{marginRight:"1rem",alignContent:"left"}}>
      <img src={s} alt="s" width="40px" style={{marginTop:"0.1rem"}}/><span className='ian'>eptian </span>
        <span> Hidayat</span>
      </a>
      <div>
        <p>
          My Portfolio was made with <img src={reactIcon} alt="React" />
        </p>
        {/* <p>
          Visitor Count: <span>{visitorCount}</span> <img src={eye} alt="eye" width="20px" />
        </p> */}
      </div>
      <div className="social-media">
        <a href="https://www.linkedin.com/in/septianhdyt" target="_blank" rel="noreferrer">
          <img src={linkedin} alt="Linkedin" />
        </a>
        <a href="https://github.com/septianhdyt/" target="_blank" rel="noreferrer">
          <img src={githubIcon} alt="GitHub" />
        </a>
        <a href="https://api.whatsapp.com/send/?phone=%2B6283149619435&text=Hello+Septian" target="_blank" rel="noreferrer">
          <img src={whatsapp} alt="Whatsapp" />
        </a>
        <a href="" target="_blank" rel="noreferrer">
          <img src={telegram} alt="telegram" />
        </a>
        <a href="https://www.instagram.com/septianhdyt9_" target="_blank" rel="noreferrer">
          <img src={instagramIcon} alt="Instagram" />
        </a>
      </div>
    </Container>
  );
}
