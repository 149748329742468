import styled from "styled-components";


export const Container = styled.section`
  position: relative;
  margin-top: 15rem;
  margin-bottom: 5rem;

  h2 {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  .projects {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none; /* Hilangkan scrollbar di Firefox */
    -ms-overflow-style: none; /* Hilangkan scrollbar di IE dan Edge */

    /* Hilangkan scrollbar di browser WebKit (Chrome, Safari) */
    &::-webkit-scrollbar {
      display: none;
    }

    .project {
      flex: 0 0 300px; /* Ukuran lebar tetap 300px */
      min-width: 300px; /* Pastikan ukuran minimal */
      max-width: 300px; /* Ukuran maksimal seragam */
      height: 420px; /* Tambahkan ruang untuk gambar di bawah */
      scroll-snap-align: center;
      padding: 1.5rem;
      background-color: #2b2b2b;
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      color: #FFF;
      overflow: hidden; /* Pastikan konten tidak keluar */

      &:hover {
        transform: translateY(-5px);
        background-color: #4a4a4a;
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 2rem;

        .project-links {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        a > img {
          width: 50px;
          height: 50px;
        }
      }

      h3 {
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
      }

      p {
        letter-spacing: 0.12rem;
        margin-bottom: 1.5rem;
        font-size: 1.2rem;

        a {
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;

          &:hover {
            color: var(--green);
          }
        }
      }

      /* Gaya gambar kecil di bawah deskripsi */
      img {
        width: 270px; /* Ukuran gambar kecil */
        height: 170px;
        border-radius: 10px; /* Rounded corners */
        margin-top: 1rem;
        align-self: center; /* Posisikan gambar di tengah */
      }

      footer {
        margin-top: auto;

        .tech-list {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 1.2rem;
          opacity: 0.6;
        }
      }
    }
  }

  
.scroll-btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fd3a4a;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scroll-btn.left {
  left: -70px;
}

.scroll-btn.right {
  right: -70px;
}
  .scroll-indicator {
    width: 100%;
    height: 5px;
    background-color: #ddd;
    position: relative;
  }

  .scroll-indicator .progress {
    width: 0;
    height: 100%;
    background-color: #4a4a4a;
    transition: width 0.3s;
  }

  @media (max-width: 960px) {
    .projects {
      gap: 1rem;
    }

    .project {
      flex: 0 0 45%; /* Atur ulang untuk resolusi lebih kecil */
      min-width: 45%;
      max-width: 45%;
    }
  }

  @media (max-width: 740px) {
    .projects {
      gap: 1rem;
    }

    .project {
      flex: 0 0 80%; /* Ukuran lebih besar di mobile */
      min-width: 80%;
      max-width: 80%;
    }
  }
`;
